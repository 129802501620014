@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins";
}

/* To remove the scrollbar from the body */
body::-webkit-scrollbar {
  width: 0;
}

*::-webkit-scrollbar {
  width: 0;
}

button {
  outline: none !important;
}

/* To remove the scroll counter from input fields when type='number' in Chrome */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

#transition-modal-description .MuiInputBase-root {
  border-radius: 6px;
}

#transition-modal-description .MuiFormLabel-root.Mui-focused {
  color: rgb(0, 153, 153);
}

#transition-modal-description
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border: 0.5px solid rgb(0, 153, 153);
}

#transition-modal-description .MuiOutlinedInput-inputAdornedStart {
  padding-left: 10px;
}
